jQuery(document).ready(function($) {


	// Track the Click to Call Event inside Analytics
	if(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch){
		$('[href*="tel:"]').on('click',function(){
			console.log('Mobile Clicked');
			if (typeof gtag_report_conversion === 'function') {
				gtag_report_conversion($(this).prop('href'));
			}

			var gtag_data = {
				'event_category': 'Click-To-Call',
				'event_action': 'Mobile Call',
			}

			// Get the event label and set if there is one
			if (this.hasAttribute('data-event-label')) {
				gtag_data.event_label = $(this).data('event-label');
			}

			// Send to Analytics
			gtag('event', 'click', gtag_data);
		});
	} else {
		$('[href*="tel:"]').on('click',function(){
			console.log('Desktop Clicked');
			return false;
		});
	}


	// ===== GLOBAL BREAKPOINT VALUES
	// ================================================================================

	var bp = {
		col4:  { min: 0,	max: 639 },
		col8:  { min: 640,	max: 959 },
		col12: { min: 960,	max: 1279 },
		col16: { min: 1280,	max: 10000 }
	};



	// ===== GLOBAL HELPER OBJECT
	// ================================================================================

	var globalHelper = {
		// Determines if an elements exists on the page
		elementExists: function(e) {
			if(e.length > 0) {
				return true;
			} else {
				return false;
			}
		},

		// Determines if an element is within the viewport
		isElementInViewport:  function(e, offset) {
			offset = ((typeof offset !== 'undefined') ? offset : 0);
			var vals = {
				eTop: (e.offset().top + offset),
				winTop: $(window).scrollTop(),
				winHeight: $(window).height(),
			};
			if((vals.winTop <= vals.eTop) && (vals.eTop < (vals.winTop + vals.winHeight))) {
				return true;
			} else {
				return false;
			}
		},

		// Scroll the browser to the given target.
		// Note:  'target' can be an integer or DOM node.
		scrollTo: function(target, duration, callback) {
			duration = ((typeof duration !== 'undefined') ? duration : 500);
			target = ((typeof target === 'object') ? target.offset().top : target);
			$('html,body').animate({ scrollTop: target }, duration);
			// Execute callback function (optional)
			if(typeof callback == 'function') {
				// Note: the callback function cannot be called within animation statement, because
				// it would fire twice based on the selector (i.e. once for html, and once for body)
				setTimeout(callback, duration);
			}
		},

		// Returns a function that, as long as it continues to be invoked, will not
		// be triggered. The function will be called after it stops being called for
		// N milliseconds. If 'immediate' is passed, trigger the function on the
		// leading edge, instead of the trailing.
		//
		// Use:  $(window).resize(globalHelper.debounce(function() { ...[your code here]... }, 500));
		// Source:  http://davidwalsh.name/javascript-debounce-function
		debounce: function(func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments;
				var later = function() {
					timeout = null;
					if(!immediate) { func.apply(context, args); }
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if(callNow) { func.apply(context, args); }
			};
		}
	};


	// HERO AREA
    // ===================================================

    var hero = {

        settings: {
            stepped_form: {
                slider: $('.hero .steps-slider'),
                options: {
					arrows: false,
					draggable: false,
                    fade: true,
					infinite: false,
					swipe: false,
					touchMove: false
                },
                nav_items: $('.hero form.stepped .steps-nav .step'),
				form_inputs: $('form.stepped .input'),
				range_slider: $('form.stepped .range-slider'),
                next_step_button: $('form.stepped .next-step')
            }
        },

        init: function() {
            if (hero.settings.stepped_form.slider.length) {
                hero.initializeSteppedForm();
                hero.bindUIActions();
            }
        },

        bindUIActions: function() {
            // On clicking "Next Step" buttons
			this.settings.stepped_form.next_step_button.on('click', function() {
				if (hero.checkRequiredFields()) {
					hero.goToNextStep();
				}
			});
			// On changing the range slider
			this.settings.stepped_form.range_slider.on('input', hero.updateRangeDisplay);
		},

		updateRangeDisplay: function() {
			$(this).trigger('change');
			$(this).siblings('.range-display').find('.value').html('$' + this.value);
		},

        initializeSteppedForm: function() {
            // Initialize the step slider
            hero.settings.stepped_form.slider.slick(hero.settings.stepped_form.options);
            // On clicking the steps
            hero.settings.stepped_form.nav_items.on('click', function() {
				// Check the current step to make sure it's valid
				if (hero.checkRequiredFields()) {
					// Get the total number of steps
					var total = hero.settings.stepped_form.nav_items.length - 1;
					// Get the current step index
					var current = hero.settings.stepped_form.slider.slick('slickCurrentSlide');
					// Get the target index
					var target = $(this).index();
					// Get the index of the step immediately after the last valid step
					var last = $('.hero form.stepped .valid').last().index();

					// Only allow going backwards, to an already valid step, or forwards to a step immediately after a valid step
					if (target < current || target == current + 1 || target == last) {
						// Deactivate all step classes
						hero.settings.stepped_form.nav_items.removeClass('active');
						// Go to that slide
						hero.settings.stepped_form.slider.slick('slickGoTo', $(this).index());
						// Activate the current step
						$(this).addClass('active');
					}
				}
            });
        },

        // If it's a stepped form, check the required fields before going to the next step
        checkRequiredFields: function(step) {

			var error = false;
			// Reset the valid class on the current step before checking
			$('.hero form.stepped .slick-active').removeClass('valid');
            // Get the required fields of the current step
			var required = $('.hero form.stepped .slick-active .required');
            // First remove all errors
            required.removeClass('error');

            if (required.length) {
                // Loop over all required fields and check that there are values
                required.each(function() {
                    // Get the input values
                    var type = $(this).find('.input, select, textarea, input:checked');
                    var val = type.val();

                    if (!val) {
                        $(this).closest('.required').addClass('error');
                        error = true;
                    }
                });
            }

            if (!error) {
				// Add valid class to current step
				$('.hero form.stepped .slick-active').addClass('valid');
				$('.hero form.stepped .step.active').addClass('valid');
                return true;
            } else {
                return false;
            }

        },

        goToNextStep: function() {
            // Go to the next slide
            hero.settings.stepped_form.slider.slick('slickNext');
            // Deactivate all step classes
            hero.settings.stepped_form.nav_items.removeClass('active');
            // Get the current slide index
            var index = hero.settings.stepped_form.slider.slick('slickCurrentSlide');
            $('.hero .steps-nav .step:eq(' + index + ')').addClass('active');

			var topOffset = document.getElementById('stepsNav').getBoundingClientRect().top + window.pageYOffset - 81;
			if (Math.min(window.screen.width, window.screen.height) < 768 && window.pageYOffset > topOffset) {
				window.scrollTo({top: topOffset, behavior: 'smooth'});
			}
		}

    }
	hero.init();


	// CONTACT FORM CONTROLS
    // ===================================================

    var form_controls = {

        settings: {
            required: $('form'),
            form_inputs: $('form .input')
        },

        init: function() {
            if (this.settings.required.length) {
                form_controls.bindUIActions();
                form_controls.initializeLabels();
            }
        },

        bindUIActions: function() {
            // Activate rows on click
            this.settings.form_inputs.on('focus', form_controls.toggleLabels);
			this.settings.form_inputs.on('blur', form_controls.toggleLabels);

			// Disable buttons when submitting forms
			this.settings.required.submit(function() {
				$(this).find('*[type="submit"]').addClass('disabled');
			});
        },

        // On page load activate labels that have a value in them
        initializeLabels: function() {
            this.settings.form_inputs.each(function () {
                if ($(this).val().length) {
                    $(this).closest('.form-row.floated').addClass('active');
                }
            });
        },

        toggleLabels: function(e) {
            // Check for empty inputs and deactivate labels on blur
            if (e.type == 'blur') {
                // If it's empty and not a date field
                if (!$(this).val().length) {
                    $(this).closest('.form-row.floated').removeClass('active');
                }
            } else {
                $(this).closest('.form-row.floated').addClass('active');
            }
        }
    }
    form_controls.init();


	// ===== FORM ELEMENTS
	// ================================================================================

	var form = {
		floated_label_input: $('.form-floated-label input, form .form-row .input'),

		init: function() {
			this.bindUIActions();
			this.formInit();
		},
		bindUIActions: function() {
			this.floated_label_input.on('focus blur keyup', this.assessElementState);
		},

		// Initialize the contact form
		formInit: function() {
			// Assess the state of each element with floated labels.
			this.floated_label_input.each(function() {
				form.assessElementState($(this));
			});
		},

		// Assesses the state of the given element.  Used to determine the state of
		// elements with floated labels.  Note the value of the parameter "e" will
		// be different depending upon who initiates the function.
		assessElementState: function(e) {
			var elem = e.type ? $(this) : e;
			var parent = elem.parents('.form-floated-label');
			if((e.type == 'blur')) {
				parent.removeClass('has-focus');
			}
			if(e.type == 'focus') {
				parent.addClass('has-focus');
			}
			if((e.type == undefined) || (e.type == 'blur') || (e.type == 'keyup')) {
				if(elem.val() == '') {
					parent.removeClass('has-value');
				} else {
					parent.addClass('has-value');
				}
			}
		}
	};
	form.init();


	// ===== GALLERY
	// ================================================================================

	var gallery = {

		settings: {
			slider: $('#gallery .slider'),
		},

		init: function() {
			if (gallery.settings.slider.length) {
				this.gallerySlider();
			}
		},

		// Gallery Slider
        gallerySlider: function() {

            gallery.settings.slider.slick({
				appendDots: '#gallery .pager',
				prevArrow: '#gallery .ctrl-prev',
				nextArrow: '#gallery .ctrl-next',
                centerMode: true,
                centerPadding: '30px',
                dots: true,
                mobileFirst: true,
                slidesToShow: 1,
                speed: 500,
                touchThreshold: 25,
                responsive: [
                    {
                        breakpoint: 479,
                        settings: {
                            centerPadding: '75px',
                        }
                    },
                    {
                        breakpoint: 959,
                        settings: {
                            centerPadding: '150px',
                        }
                    },
                    {
                        breakpoint: 1279,
                        settings: {
                            centerPadding: '210px',
                        }
                    },
                ]
            });

        },

	}
	gallery.init();


	// ===== APP
	// ================================================================================

	var app = {
		body: $('body'),
		nav_trigger: $('.header-nav-trigger'),
		jump_link: $('.jump-link'),
		more_info_button: $('.more-info .button'),
		expandable_content: $('.expandable-content'),
		carousel: {
			elem: $('.section-testimonials .carousel .slides'),
			options: {
				adaptiveHeight: true,
				autoplay: false,
				dots: true,
				appendDots: '.section-testimonials .carousel .pager',
				prevArrow: '.section-testimonials .carousel .ctrl-prev',
				nextArrow: '.section-testimonials .carousel .ctrl-next',
			}
		},

		init: function() {
			this.bindUIActions();
			this.carouselInit();
			this.tabMessage();
		},
		bindUIActions: function() {
			this.nav_trigger.on('click', this.toggleNav);
			this.jump_link.on('click', this.goToLink);
			// On clicking the MORE INFO button
			this.more_info_button.on('click', this.expandContent);
		},

		// Expand the rest of the main content
		expandContent: function() {
			app.more_info_button.toggleClass('open');
			app.expandable_content.toggleClass('open');
		},

		// Update the browser tab to display custom messages
		tabMessage: function() {
			// Get the current page title
			var pageTitle = $('TITLE').text();
			// Get the custom tab message
			var customTitle = $('TITLE').data('tab-message');
			// On blur update with custom message
			$(window).blur(function() {
				$('TITLE').text(customTitle + ' ' + pageTitle);
			});
			// Revert to default title on focus
			$(window).focus(function() {
				$('TITLE').text(pageTitle);
			});
		},

		// Toggle display of the main navigation
		toggleNav: function() {
			app.body.toggleClass('header-nav-enabled');
		},

		// Scrolls to the target associated with the clicked link
		goToLink: function() {
			var target = $('a[name="'+$(this).data('target')+'"]');
			if(globalHelper.elementExists(target)) {
				app.body.removeClass('header-nav-enabled');
				globalHelper.scrollTo(target);
				return false;
			}
		},

		// Testimonial Carousel:  Initialize
		carouselInit: function() {
			this.carousel.elem.slick(this.carousel.options);
		}

	};
	app.init();


	// ===== MODAL
	// ================================================================================

	var modal = {
		settings: {
			open_trigger: $('.modal-trigger'),
			close_trigger: $('.modal-close')
		},
		init: function() {
			this.bindUIActions();
		},
		bindUIActions: function() {
			this.settings.open_trigger.on('click', function(e) {
				var selector = $(this).data('modal');
				modal.handleDisplay($(selector), 'show');
				return false;
			});
			this.settings.close_trigger.on('click', function(e) {
				modal.handleDisplay($(this).parents('.modal'), 'hide');
			});
		},

		// Handles the display (show/hide) of the modal
		handleDisplay: function(modal, action) {
			if(action == 'show') {
				modal.fadeIn(300);
			}
			if(action == 'hide') {
				modal.fadeOut(300);
			}
		}
	};
	modal.init();


	// ===== STICKY FOOTER FORM
	// ==========================================

	var sticky_footer = {

		settings: {
			hero: $('.section-hero'),
			bar: $('.sticky-footer-bar'),
			offer_button: $('.js-form-modal'),
			close_button: $('.sticky-footer-form .close-button'),
			textareas: $('.sticky-footer-form textarea'),
		},

		init: function() {
			if (this.settings.bar.length) {
				this.bindUIActions();

				// Show sticky footer form after scroll X amount
				$(window).on('scroll', globalHelper.debounce(function() {
					if ($(window).scrollTop() > sticky_footer.settings.hero.height() * 0.5) {
						sticky_footer.settings.bar.addClass('active');
						// Remove scroll listener
						$(window).off('scroll');
					}
				}, 250));
			}
		},

		bindUIActions: function() {
			// Open Form Button
			sticky_footer.settings.offer_button.on('click', sticky_footer.toggleForm);
			// Close Form Button
			sticky_footer.settings.close_button.on('click', sticky_footer.toggleForm);
			// Expand textareas on focus
			sticky_footer.settings.textareas.on('focus', function() {
				$(this).addClass('expanded');
			});
		},

		// Toggle sticky footer form open/close
		toggleForm: function() {
			if (!sticky_footer.settings.bar.hasClass('open')) {
				sticky_footer.settings.bar.addClass('open');
			} else {
				sticky_footer.settings.bar.removeClass('open');
			}
		},

	};
	sticky_footer.init();


});
